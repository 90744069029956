// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { Button, Container, Grid, IconButton, InputAdornment, ThemeProvider, styled } from '@mui/material';
import { selectIsAuthenticated } from '@opentalk/redux-oidc';
import { RoomId } from '@opentalk/rest-api-rtk-query';
import { useFormik } from 'formik';
import i18next from 'i18next';
import { isE2EESupported } from 'livekit-client';
import { uniqueId } from 'lodash';
import { SnackbarKey } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ApiErrorWithBody, StartRoomError, useGetMeQuery, useGetRoomEventInfoQuery } from '../../api/rest';
import { HiddenIcon, VisibleIcon } from '../../assets/icons';
import { createOpenTalkTheme } from '../../assets/themes/opentalk';
import { CommonTextField as DefaultCommonTextField, notifications } from '../../commonComponents';
import SuspenseLoading from '../../commonComponents/SuspenseLoading/SuspenseLoading';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useInviteCode } from '../../hooks/useInviteCode';
import useNavigateToHome from '../../hooks/useNavigateToHome';
import { useUpdateDocumentTitle } from '../../hooks/useUpdateDocumentTitle';
import { useMediaChoices } from '../../provider/MediaChoicesProvider';
import { startRoom } from '../../store/commonActions';
import { selectDisallowCustomDisplayName, selectFeatures } from '../../store/slices/configSlice';
import {
  ConnectionState,
  InviteCodeErrorEnum,
  fetchRoomByInviteId,
  selectInviteState,
  selectPasswordRequired,
  selectRoomConnectionState,
} from '../../store/slices/roomSlice';
import { BreakoutRoomId, FetchRequestError } from '../../types';
import { composeRoomPath } from '../../utils/apiUtils';
import { formikProps } from '../../utils/formikUtils';
import { ContitionalToolTip } from '../ConditionalToolTip/ContitionalToolTip';
import OpentalkError from '../Error';
import ImprintContainer from '../ImprintContainer';
import SelfTest from '../SelfTest';

const CommonTextField = styled(DefaultCommonTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '&:not(&.Mui-focused)': {
      backgroundColor: theme.palette.text.primary,
    },
  },
  '& .MuiInputLabel-root': {
    color: theme.palette.secondary.contrastText,
    '&.Mui-error': {
      color: theme.palette.text.secondary,
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: theme.palette.text.primary,
  },
}));

const CustomTextField = styled(CommonTextField)(({ theme }) => ({
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.contrastText,
  },
}));

const ActionButton = styled(Button)({
  height: '100%',
});

let wrongPasswordSnackBarKey: SnackbarKey | undefined = undefined;

const showWrongPasswordNotification = () => {
  if (wrongPasswordSnackBarKey) return;
  wrongPasswordSnackBarKey = notifications.toast(`${i18next.t('joinform-wrong-room-password')}`, {
    //Unique key is used to guarantee we will show a notification if user repeatedly inputs a wrong password
    key: uniqueId(),
    variant: 'error',
    persist: true,
    onClose: () => {
      wrongPasswordSnackBarKey = undefined;
    },
  });
};

export const JOIN_FORM_ID = 'join-form';

const LobbyView = () => {
  const { t } = useTranslation();
  const mediaChoices = useMediaChoices();

  const dispatch = useAppDispatch();
  const inviteState = useAppSelector(selectInviteState);
  const { joinWithoutMedia } = useAppSelector(selectFeatures);
  const showPasswordField = useAppSelector(selectPasswordRequired);
  const disallowCustomDisplayName = useAppSelector(selectDisallowCustomDisplayName);
  const isLoggedIn = useAppSelector(selectIsAuthenticated);
  const connectionState = useAppSelector(selectRoomConnectionState);

  const { data } = useGetMeQuery(undefined, { skip: !isLoggedIn });
  const navigateToHome = useNavigateToHome();
  const inviteCode = useInviteCode();
  const navigate = useNavigate();

  const [inviteCodeError, setInviteCodeError] = useState<FetchRequestError>();
  const [showPassword, setShowPassword] = useState(false);

  const { roomId, breakoutRoomId } = useParams<'roomId' | 'breakoutRoomId'>() as {
    roomId: RoomId;
    breakoutRoomId?: BreakoutRoomId;
  };

  const { data: roomData } = useGetRoomEventInfoQuery({ id: roomId, inviteCode: inviteCode }, { skip: !roomId });

  if (roomData?.e2EEncryption && !isE2EESupported()) {
    notifications.error(t('unsupported-browser-e2e-encryption-dialog-message'));
  }

  useUpdateDocumentTitle(t('joinform-room-title', { title: roomData?.title || '' }), {
    extension: '',
  });

  // Temporary request to figure out if we need to show a password field until it is added in getEventInfo request - https://git.opentalk.dev/opentalk/backend/services/controller/-/issues/603
  useEffect(() => {
    if (inviteCode && !inviteState.inviteCode) {
      dispatch(fetchRoomByInviteId(inviteCode))
        .unwrap()
        .catch((error) => setInviteCodeError(error));
    }
  }, [inviteCode]);

  useEffect(() => {
    mediaChoices?.saveAudioInputEnabled(false);
    mediaChoices?.saveVideoInputEnabled(false);
  }, [mediaChoices?.saveAudioInputEnabled, mediaChoices?.saveVideoInputEnabled]);

  //Cleans up wrong password notification on dismount
  useEffect(() => {
    return () => {
      if (wrongPasswordSnackBarKey) {
        notifications.close(wrongPasswordSnackBarKey);
        wrongPasswordSnackBarKey = undefined;
      }
    };
  }, []);

  const disableDisplayNameField = disallowCustomDisplayName && !inviteCode;
  const initialDisplayName = data?.displayName || '';

  const validationSchema = useMemo(
    () =>
      yup.object({
        name: yup
          .string()
          .trim()
          .required(t('field-error-required', { fieldName: 'Name' })),
      }),
    [t]
  );

  const enterRoom = useCallback(
    async (displayName: string, password: string) => {
      if (joinWithoutMedia) {
        mediaChoices?.saveAudioInputEnabled(false);
        mediaChoices?.saveVideoInputEnabled(false);
      }

      return dispatch(
        startRoom({
          roomId,
          breakoutRoomId: breakoutRoomId || null,
          displayName,
          password,
          inviteCode,
        })
      )
        .unwrap()
        .catch((e) => {
          if ('code' in e) {
            const error = e as ApiErrorWithBody<StartRoomError>;
            switch (error.code) {
              case StartRoomError.InvalidBreakoutRoomId:
              case StartRoomError.NoBreakoutRooms:
                notifications.info(t('breakout-notification-session-ended-header'));
                navigate(composeRoomPath(roomId, inviteCode, breakoutRoomId));
                break;
              case StartRoomError.InvalidJson:
                console.error('invalid json request in startRoom', e);
                notifications.error(t('error-general'));
                break;
              case StartRoomError.WrongRoomPassword:
              case StartRoomError.InvalidCredentials:
                showWrongPasswordNotification();
                break;
              case StartRoomError.NotFound:
                notifications.error(t('joinform-room-not-found'));
                navigateToHome();
                break;
              case StartRoomError.Forbidden:
                notifications.error(t('joinform-access-denied'));
                navigateToHome();
                break;
              case StartRoomError.BadRequest:
                notifications.error(t('error-invalid-invitation-code'));
                navigateToHome();
                break;
              default:
                console.error(`unknown error code ${e.code} in startRoom`, e);
                notifications.error(t('error-general'));
            }
          } else {
            console.error('unknown error in startRoom', e);
            notifications.error(t('error-general'));
          }
        });
    },
    [navigate, t, breakoutRoomId, roomId, inviteCode, dispatch, navigateToHome, mediaChoices, joinWithoutMedia]
  );

  const formik = useFormik({
    initialValues: {
      name: initialDisplayName,
      password: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      if (isLoggedIn || inviteCode !== undefined) {
        const name = disableDisplayNameField ? initialDisplayName : values.name;
        enterRoom(name, values.password);
      }
    },
  });

  const disableSubmitButton =
    !(isLoggedIn || inviteCode !== undefined) || connectionState === ConnectionState.Starting || !formik.isValid;

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  if (inviteState.loading) {
    return <SuspenseLoading />;
  }

  if (inviteCodeError) {
    if (inviteCodeError?.statusText === InviteCodeErrorEnum.InvalidJson) {
      return <OpentalkError title={t('error-invalid-invitation-link')} />;
    }
    return <OpentalkError title={t('error-invite-link')} />;
  }

  return (
    <>
      <Container>
        <SelfTest
          actionButton={
            <ActionButton form={JOIN_FORM_ID} type="submit" disabled={disableSubmitButton}>
              {t('joinform-enter-now')}
            </ActionButton>
          }
        >
          <ThemeProvider theme={createOpenTalkTheme('dark')}>
            <Grid
              container
              item
              spacing={1}
              justifyContent="center"
              flexWrap="nowrap"
              direction="row"
              sm={12}
              md="auto"
              component="form"
              id={JOIN_FORM_ID}
              onSubmit={formik.handleSubmit}
            >
              <Grid item sm={6} md="auto">
                <ContitionalToolTip
                  showToolTip={Boolean(disableDisplayNameField)}
                  title={t('joinform-display-name-field-disabled-tooltip')}
                >
                  <CustomTextField
                    {...formikProps('name', formik)}
                    label={t('global-name')}
                    placeholder={t('lobby-name-placeholder')}
                    autoComplete="username"
                    disabled={disableDisplayNameField}
                  />
                </ContitionalToolTip>
              </Grid>
              {showPasswordField && (
                <Grid item sm={6} md="auto">
                  <CommonTextField
                    {...formikProps('password', formik)}
                    label={t('global-password')}
                    placeholder={t('lobby-password-placeholder')}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label={t('toggle-password-visibility')}
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {!showPassword ? <VisibleIcon /> : <HiddenIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </ThemeProvider>
        </SelfTest>
      </Container>
      <ImprintContainer />
    </>
  );
};

export default LobbyView;
